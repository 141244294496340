/**
 * @file 快速开始组件的请求方法
 * @author FengGuang(fengguang01@baidu.com)
 */

import request from 'superagent';
import baseRequest from '../baseApi';


/**
 * 获取快速开始选项
 *
 * @return {Object} promise对象
 */
export const fetchGetQuickStartSelector = (): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/content/list')
            .query({contentType: 'paddleOrgQuickInstallSelector'})
    );


/**
 * 获取快速开始
 *
 * @return {Object} promise对象
 */
export const fetchGetQuickStart = (): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/content/list')
            .query({contentType: 'paddleOrgQuickInstall'})
    );
