/**
 * @file 快速开始组件的 store
 * @author FengGuang(fengguang01@baidu.com)
 */

import {useCallback, useEffect, useState} from 'react';

import {
    fetchGetQuickStartSelector,
    fetchGetQuickStart
} from '../../api/components/quickStartApi';

import {
    IQuickStartSelectorItem,
    IQuickStartSelectorGroup,
    IQuickStartItem, IStateSelected, IStateShowInfo
} from './type.d';
import React from 'react';
import isNull from '../../utils/is-null';

// 预请求
let preRequest: any = {};
if (window) {
    preRequest = {
        fetchGetQuickStartSelector: fetchGetQuickStartSelector(),
        fetchGetQuickStart: fetchGetQuickStart()
    };
}

export const useQuickStartSelector = () => {
    const [quickStartSelector, setQuickStartSelector] = useState<IQuickStartSelectorGroup[]>([]);

    useEffect(() => {
        (async (): Promise<void> => {
            const fetchFunc = preRequest.fetchGetQuickStartSelector || fetchGetQuickStartSelector();
            delete preRequest.fetchGetQuickStartSelector;

            let res;
            try {
                res = await fetchFunc;
            }
            catch (err) {
                //ignore
            }
            if (res) {
                const result = res.body.result;
                const selectorList: IQuickStartSelectorItem[] = result.data || [];

                const newQuickStartSelector: IQuickStartSelectorGroup[] = [];
                // 根据 selectorList[i].groupKey 进行分组
                selectorList.forEach(item => {
                    let selector = newQuickStartSelector.find(group => group.groupKey === item.groupKey);
                    if (!selector) {
                        selector = {
                            groupName: item.groupName,
                            groupKey: item.groupKey,
                            // 选项内容列表
                            list: [] as IQuickStartSelectorItem[]
                        };
                        newQuickStartSelector.push(selector);
                    }
                    selector.list.push(item);
                });
                setQuickStartSelector(newQuickStartSelector);
            }
        })();
    }, []);

    return quickStartSelector;
};

export const useQuickStartList = () => {
    const [quickStartList, setQuickStartList] = useState<IQuickStartItem[]>([]);

    useEffect(() => {
        (async (): Promise<void> => {
            const fetchFunc = preRequest.fetchGetQuickStart || fetchGetQuickStart();
            delete preRequest.fetchGetQuickStart;

            let res;
            try {
                res = await fetchFunc;
            }
            catch (err) {
                // ignore
            }
            if (res) {
                const result = res.body.result;

                const startList: IQuickStartItem[] = result.data || [];
                setQuickStartList(startList);
            }
        })();
    }, []);

    return quickStartList;
};

// 根据 checked 列出的条件匹配 quickStartList
const filterQuickStartList = (
    list: IQuickStartItem[],
    checked: IStateSelected
): IQuickStartItem[] => {
    const resList: IQuickStartItem[] = [];
    const checkedList = Object.entries(checked);
    list.forEach(item => {
        // 在 js 中空可能用 null、''、undefined 表示，所以需要单独判断左右两边都是空的情况
        const matched = checkedList.every(([key, value]) => (
            item[key] === value || (isNull(item[key]) && isNull(value))
        ));
        if (matched) {
            resList.push(item);
        }
    });
    return resList;
};

// 更新选项的禁用状态
// 本方法会遍历 quickStartList ，根据已选项将部分未选项禁用，
// 这个过程可以看做是一种搜索过滤的过程，根据用户的选择过滤出 quickStartList 中的一些数据
// 但是需要阻止用户做出结果为空的选择。
const updateDisabledState = (
    newChecked: IStateSelected,
    quickStartSelector: IQuickStartSelectorGroup[],
    quickStartList: IQuickStartItem[]
): { disabled: IStateSelected, showInfo: IStateShowInfo } => {
    // 每一个 quickStartSelector[i] 中需要禁用的选项 = 除自己以外的 selector 的值筛选出的条目，不包括的选项
    // 需要被禁用的选项的列表，按照 quickStartSelector[i].key 进行分组。初始状态是所有选项都被禁用，
    // 之后再从中间减去可以选择的选项
    const newDisabled: IStateSelected = {};

    // 遍历 quickStartSelector，得到需要禁用的选项
    quickStartSelector.forEach(group => {
        // 除了当前 group 以外，其他的 group 的值作为筛选条件，筛选 quickStartList
        const needCheck: IStateSelected = {};
        Object.entries(newChecked)
            .filter(([key, value]) => !!value && key !== group.groupKey)
            .forEach(([key, value]) => {
                needCheck[key] = value;
            });
        const avaliableList = filterQuickStartList(quickStartList, needCheck);
        // 得到筛选后的列表 avaliableList，
        // 列表中的 avaliableList[n][group.key] 就是当前 group 的可选项，补集就是禁用项
        // 这里使用删除的方法取补集
        const thisGroupDisabled = new Set(group.list.map(i => i.optionKey));
        avaliableList.forEach(item => {
            thisGroupDisabled.delete(item[group.groupKey]);
        });
        newDisabled[group.groupKey] = Array.from(thisGroupDisabled);
    });

    // 用户已经做出的选择匹配到的所有项目
    const avaliableList = filterQuickStartList(quickStartList, newChecked);
    // 如果可能的结果只剩1个，或者所有表单域都已经选择了，说明此时应该将结果展示出来
    let installInformation = '';
    let verificationInformation = '';
    let documentation = '';
    if (avaliableList.length === 1) {
        installInformation = avaliableList[0].installInformation;
        verificationInformation = avaliableList[0].verificationInformation;
        documentation = avaliableList[0].documentation;
    }
    else if (avaliableList.length > 1) {
        const allSelector = quickStartSelector.filter(e => e.list && e.list.length > 0);
        if (Object.keys(newChecked).length >= allSelector.length) {
            installInformation = avaliableList[0].installInformation;
            verificationInformation = avaliableList[0].verificationInformation;
            documentation = avaliableList[0].documentation;
        }
    }

    return {
        disabled: newDisabled,
        showInfo: {
            installInformation,
            verificationInformation,
            documentation,
        }
    };
};

const useQuickStartStore = () => {
    const quickStartSelector = useQuickStartSelector();
    const quickStartList = useQuickStartList();

    const [checked, setChecked] = useState<IStateSelected>({});
    const [disabled, setDisabled] = useState<IStateSelected>({});
    const [showInfo, setShowInfo] = useState<IStateShowInfo>({
        installInformation: '',
        verificationInformation: '',
        documentation: ''
    });

    // 切换选项，更新展示内容
    const handleOnItemChange = useCallback((
        event: React.MouseEvent<HTMLElement, MouseEvent>,
        groupKey: string,
        optionKey: string
    ): void => {
        const newChecked: IStateSelected = {
            ...checked,
            [groupKey]: optionKey
        };

        // 如果点击已经选中的选项，会取消选中状态
        if (checked[groupKey] === optionKey) {
            newChecked[groupKey] = '';
            newChecked[groupKey] = '';
        }

        const updateRes = updateDisabledState(newChecked, quickStartSelector, quickStartList);

        setDisabled(updateRes.disabled);
        setShowInfo(updateRes.showInfo);
        setChecked(newChecked);
    }, [checked, quickStartList, quickStartSelector]);

    return {
        quickStartSelector,
        quickStartList,
        checked,
        disabled,
        showInfo,

        handleOnItemChange
    };
};

export default useQuickStartStore;
