/**
 * @file 优势的数据
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';

export interface IAdvantageItem {
    id: number,
    title: string,
    text: string | React.ReactNode
}

const advantageData: IAdvantageItem[] = [
    {
        id: 1,
        title: '同时支持动态图和静态图，兼顾灵活性和高性能',
        text: (
            <span>
                飞桨同时为用户提供动态图和静态图两种计算图。动态图组网更加灵活、调试网络便捷，实现AI 想法更快速；静态图部署方便、运行速度快，应用落地更高效
            </span>
        )
    },
    {
        id: 2,
        title: '源于实际业务淬炼，提供应用效果领先的官方模型',
        text: (
            <span>
                飞桨提供的80+官方模型，全部经过真实应用场景的有效验证。不仅包含“更懂中文”的NLP 模型，同时开源多个视觉领域国际竞赛冠军算法
            </span>
        )
    },
    {
        id: 3,
        title: '源于产业实践，输出业界领先的超大规模并行深度学习平台能力',
        text: (
            <span>
                飞桨同时支持稠密参数和稀疏参数场景的超大规模深度学习并行训练，支持千亿规模参数、数百个节点的高效并行训练，提供强大的深度学习并行技术
            </span>
        )
    }
];

export default advantageData;
