/**
 * @file markdown 容器
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import classNames from 'classnames';
import copy from 'copy-text-to-clipboard';

import CopyBtn from './CopyBtn';

interface IProps {
    className?: string;
    source?: string;
}


// 覆盖默认的渲染结果，可覆盖选项参考源码
// https://github.com/rexxars/react-markdown/blob/master/src/renderers.js
const renderersOption = {
    code(props: any) {
        let className = '';
        if (props.language) {
            className = 'language-'.concat(props.language);
        }
        return (
            <pre>
                <code className={className}>
                    {props.value}
                </code>
                <CopyBtn onClick={() => copy(props.value)} />
            </pre>
        );
    }
};

const MarkdownContainer: React.FC<IProps> = props => {
    const {source, className} = props;
    return (
        <ReactMarkdown
            className={classNames('markdown-body', className)}
            source={source}
            escapeHtml={false}
            renderers={renderersOption}
        />
    );
};

export default MarkdownContainer;
