/**
 * @file logo墙
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';

export interface IPartnerItem {
    id: number;
    indexArr: string;
    iconUrl: string;
}

interface IProps {
    partnerList: IPartnerItem[];
}

const Partner: React.FC<IProps> = props => {
    const partnerList = props.partnerList;

    return (
        <div className="paddle-user-list-wrap">
            <div className="paddle-user-list-group">
                {partnerList.map(item => (
                    <div
                        key={item.id}
                        className="paddle-user-list-item-wrap"
                    >
                        <div
                            className="paddle-user-list-item"
                            style={{backgroundImage: `url(${item.iconUrl})`}}
                        >
                            <img alt={item.indexArr} src={item.iconUrl} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default React.memo(Partner);
