/**
 * @file paddle 首页的请求方法
 * @author FengGuang(fengguang01@baidu.com)
 */

import request from 'superagent';
import baseRequest from '../baseApi';


// 获取首页的banner
export const fetchGetIndexBanner = (): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/content/list')
            .query({contentType: 'paddleOrgIndexBanner'})
    );

// 获取首页的最新资讯
export const fetchGetIndexNews = (): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/content/list')
            .query({contentType: 'paddleOrgIndexOperationArea'})
    );

// 获取合作伙伴
export const fetchGetIndexPartner = (): Promise<request.Response> =>
    baseRequest(
        request.get('/platform/content/list')
            .query({contentType: 'paddleOrgIndexPartner'})
    );
