/**
 * @file 首页banner
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';
import {Button} from 'antd';

const IndexBanner: React.FC = () => {
    return (
        <div className="paddle-index-banner-wrap">
            <div className="paddle-index-banner">
                {/*<a className="paddle-index-banner-full-size-link" />*/}
                <div className="paddle-index-banner-title">源于产业实践的开源深度学习平台</div>
                <div className="paddle-index-banner-text">飞桨致力于让深度学习技术的创新与应用更简单</div>
                <div className="paddle-index-banner-btn-group">
                    <a href="/start">
                        <Button className="paddle-index-banner-btn">开始使用</Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default React.memo(IndexBanner);
