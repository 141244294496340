/**
 * @file paddle 首页的 hooks
 * @author FengGuang(fengguang01@baidu.com)
 */

import {useEffect, useState} from 'react';

import {IPartnerItem} from './components/partner/Partner';
import {INewsItem} from './components/news/News';
import {
    fetchGetIndexPartner,
    fetchGetIndexNews
} from '../../api/pages/indexApi';

// 预请求
let preRequest: any = {
    fetchGetIndexPartner: fetchGetIndexPartner(),
    fetchGetIndexNews: fetchGetIndexNews()
};

export interface IIndexStore {
    partnerList: IPartnerItem[];
    newsList: INewsItem[]
}

const useIndexStore = () => {
    const [partnerList, setPartnerList] = useState<IPartnerItem[]>([]);
    const [newsList, setNewsList] = useState<INewsItem[]>([]);

    useEffect(() => {
        const getIndexPartner = async (): Promise<void> => {
            const fetchFunc = preRequest.fetchGetIndexPartner || fetchGetIndexPartner();
            delete preRequest.fetchGetIndexPartner;

            let res;
            try {
                res = await fetchFunc;
            }
            catch (err) {
                // ignore
            }
            if (res) {
                const list: IPartnerItem[] = res.body.result.data || [];
                setPartnerList(list);
            }
        };

        getIndexPartner();
    }, []);

    useEffect(() => {
        (async (): Promise<void> => {
            const fetchFunc = preRequest.fetchGetIndexNews || fetchGetIndexNews();
            delete preRequest.fetchGetIndexNews;

            let res;
            try {
                res = await fetchFunc;
            }
            catch (err) {
                // ignore
            }
            if (res) {
                const list: any[] = res.body.result.data || [];
                setNewsList(
                    list.map(item => ({
                        id: item.id,
                        title: item.title,
                        text: item.text,
                        btnText: item.btnName,
                        btnLink: item.btnUrl
                    }))
                );
            }
        })();
    }, []);

    return {
        partnerList,
        newsList
    };
};

export default useIndexStore;
