/**
 * @file paddle 首页
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';

import Header from '../../components/header/HeaderWithStore';
import Footer from '../../components/footer/footer';
import Advantage from './components/advantage/Advantage';
import Banner from './components/banner/IndexBanner';
import News from './components/news/News';
import Partner from './components/partner/Partner';
import QuickStart from '../../components/quick-start/QuickStart';

import useIndexStore from './indexHooks';


const IndexIndex: React.FC = () => {
    const {
        partnerList,
        newsList
    } = useIndexStore();

    return (
        <div className="page paddle-index-page">
            <Header />
            <Banner />
            <div className="paddle-index-content-wrap">
                <div className="paddle-index-content">
                    <div className="paddle-index-left">
                        <div className="paddle-index-page-context-h1">
                            优势
                        </div>
                        <Advantage />

                        <div className="paddle-index-page-context-h1">
                            快速安装
                        </div>
                        <QuickStart
                            trackeventOptLabel="首页"
                        />

                        <div className="paddle-index-page-context-h1">
                            最新资讯
                        </div>
                        <News newsList={newsList} />

                        <div className="paddle-index-page-context-h1">
                            他们都在用
                        </div>
                        <Partner
                            partnerList={partnerList}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default React.memo(IndexIndex);
