/**
 * @file 快速开始组件
 * @author FengGuang(fengguang01@baidu.com)
 *
 */

import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Button} from 'antd';

import MarkdownContainer from '../markdown-container/MarkdownContainer';

import useQuickStartStore from './quickStartHooks';

export interface IProps {
    trackeventOptLabel: string;
}

const QuickStart: React.FC<IProps> = props => {
    const {
        trackeventOptLabel
    } = props;

    const [isPushedTrackevent, setIsPushedTrackevent] = useState<boolean>(false);

    const {
        quickStartSelector,
        checked,
        disabled,
        showInfo,

        handleOnItemChange
    } = useQuickStartStore();


    const isShowInfo = !!(
        showInfo.installInformation
        || showInfo.verificationInformation
        || showInfo.documentation
    );

    // 如果有展示信息，则触发一次埋点事件
    useEffect(() => {
        if (!isPushedTrackevent && (window as any)._hmt && isShowInfo) {
            (window as any)._hmt.push(['_trackEvent', '安装', '快速安装方案生成', trackeventOptLabel]);
            setIsPushedTrackevent(true);
        }
    }, [isPushedTrackevent, isShowInfo, trackeventOptLabel]);

    return (
        <div className="paddle-quick-start">
            {
                quickStartSelector.length > 0
                && <div className="paddle-quick-start-content">
                    {quickStartSelector.map(group => (
                        <div key={group.groupKey} className="paddle-quick-start-item">
                            <div className="paddle-quick-start-item-title">
                                {group.groupName}
                            </div>
                            <div className="paddle-quick-start-item-children">
                                <Button.Group size="large">
                                    {group.list.map((btnItem, btnItemIndex, arr) => {
                                        const isDisabled = !!disabled[btnItem.groupKey]
                                            && disabled[btnItem.groupKey].indexOf(btnItem.optionKey) > -1;
                                        return (
                                            <Button
                                                key={btnItem.optionKey}
                                                className={classNames({
                                                    large: arr.length <= 2,
                                                    checked: checked[group.groupKey] === btnItem.optionKey,
                                                    disabled: isDisabled
                                                })}
                                                disabled={isDisabled}
                                                onClick={event => handleOnItemChange(event, group.groupKey, btnItem.optionKey)}
                                            >
                                                <span>{btnItem.optionName}</span>
                                            </Button>
                                        );
                                    })}
                                </Button.Group>
                            </div>
                        </div>
                    ))}
                </div>
            }


            {
                isShowInfo
                && <div className="paddle-quick-start-result">
                    {
                        !!showInfo.installInformation
                        && <div>
                            <div className="paddle-quick-start-result-title">
                                安装信息
                            </div>
                            <div className="paddle-quick-start-result-text">
                                <MarkdownContainer
                                    source={showInfo.installInformation}
                                />
                            </div>
                        </div>
                    }
                    {
                        !!showInfo.verificationInformation
                        && <div>
                            <div className="paddle-quick-start-result-title">
                                验证信息
                            </div>
                            <div className="paddle-quick-start-result-text">
                                <MarkdownContainer
                                    source={showInfo.verificationInformation}
                                />
                            </div>
                        </div>
                    }
                    {
                        !!showInfo.documentation
                        && <div>
                            <div className="paddle-quick-start-result-title">
                                说明信息
                            </div>
                            <div className="paddle-quick-start-result-text">
                                <MarkdownContainer
                                    source={showInfo.documentation}
                                />
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
};

export default QuickStart;
