/**
 * @file 优势
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';

import advantageData from './advantageData';
import AdvantageItem from './AdvantageItem';

const Advantage: React.FC = () => {
    return (
        <div className="paddle-advantage-group">
            {advantageData.map((item, index) => (
                <AdvantageItem
                    key={item.id}
                    option={item}
                />
            ))}

            <div className="paddle-advantage-more-wrap">
                <a
                    className="paddle-advantage-more"
                    href="/feature"
                >
                    了解更多飞桨特性
                </a>
            </div>
        </div>
    );
};

export default React.memo(Advantage);
