/**
 * @file 优势
 * @author FengGuang(fengguang01@baidu.com)
 */

import React, {useEffect, useRef, useState} from 'react';

import {IAdvantageItem} from './advantageData';

export interface IProps {
    option: IAdvantageItem;
}

const AdvantageItem: React.FC<IProps> = props => {
    const [showEllipsis, setShowEllipsis] = useState<boolean>(false);
    const textRef = useRef<HTMLDivElement>(null);
    const textInnerRef = useRef<HTMLDivElement>(null);

    const {id, title, text} = props.option;
    // 检查是否需要显示省略号，如果需要则会触发重新渲染
    useEffect(() => {
        if (textRef.current && textInnerRef.current) {
            const textHeight = textRef.current.getBoundingClientRect().height;
            const textInnerHeight = textInnerRef.current.getBoundingClientRect().height;
            const newShowEllipsis = textHeight < textInnerHeight;
            if (showEllipsis !== newShowEllipsis) {
                setShowEllipsis(newShowEllipsis);
            }
        }
    }, [text, showEllipsis, setShowEllipsis]);

    // 监听窗口大小变化事件，调整是否显示省略号
    useEffect(() => {
        // didMount
        const resizeMethod = () => {
            if (textRef.current && textInnerRef.current) {
                const textHeight = textRef.current.getBoundingClientRect().height;
                const textInnerHeight = textInnerRef.current.getBoundingClientRect().height;
                const newShowEllipsis = textHeight < textInnerHeight;
                if (showEllipsis !== newShowEllipsis) {
                    setShowEllipsis(newShowEllipsis);
                }
            }
        };
        window.addEventListener('resize', resizeMethod);
        // willUnmount
        return () => {
            window.removeEventListener('resize', resizeMethod);
        };
    }, [showEllipsis]);

    return (
        <div key={id} className="paddle-advantage-item">
            <div className="paddle-advantage-item-content">
                <div className="paddle-advantage-item-title">
                    {title}
                </div>
                <div className="paddle-advantage-item-text" ref={textRef}>
                    <div
                        className="paddle-advantage-item-text-inner"
                        ref={textInnerRef}
                    >
                        {text}
                    </div>
                    {
                        !!showEllipsis
                        && <div className="paddle-advantage-item-text-ellipsis">...</div>
                    }
                </div>
            </div>
        </div>
    );
};

export default React.memo(AdvantageItem);
