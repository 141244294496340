/**
 * @file 新闻
 * @author FengGuang(fengguang01@baidu.com)
 */

import React from 'react';


export interface INewsItem {
    id: number,
    title: string,
    text: string | React.ReactNode,
    btnText?: string,
    btnLink?: string
}

export interface IProps {
    newsList: INewsItem[];
}

const News: React.FC<IProps> = props => {
    return (
        <div className="paddle-news-group">
            {props.newsList.map(item => (
                <div key={item.id} className="paddle-news-item">
                    <div className="paddle-news-item-title">
                        {item.title}
                    </div>
                    <div className="paddle-news-item-text">
                        {item.text}
                    </div>
                    <div className="paddle-news-item-more-wrap">
                        <a
                            className="paddle-news-item-more"
                            href={item.btnLink}
                        >
                            {item.btnText} {'>'}
                        </a>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default React.memo(News);
