/**
 * @file 请求方法基础
 * @author FengGuang(fengguang01@baidu.com)
 */

import {message} from 'antd';
import request from 'superagent';


const baseRequest = async (superagentObject: request.SuperAgentRequest): Promise<request.Response> => {
    let res: request.Response;
    try {
        res = await superagentObject;
        const body = res.body;
        if (!body || !body.result) {
            throw new Error('网络好像有点问题');
        }
        if (body.errorCode !== 0) {
            throw new Error(body.errorMsg);
        }
    }
    catch (err) {
        (window as any).DEBUG && message.error(err.toString());
        throw err;
    }

    return res;
};

export default baseRequest;
